import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function MaaHomesReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Maa Homes Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Maa Homes is a transparent Real Estate company working in India. It takes pride in being there for the customer throughout and posts the process of purchase. “Built on Relationships'', Maa Homes has a firm belief in building trust with the clients and delivering what is promised.
</p>

            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                    <p className="AboutExpoPara">Maa Homes is the result of a shared vision of Subha Builders & Developers and Pleasure Spaces (India). A vision to collaborate and provide a unique housing experience to the people of India. The special concept of 360degree customer service helps realize this vision. With the proven quality of the 40 years of combined experience of Subha Builders & Pleasure Spaces, Maa Homes is the catalyst to unlock new possibilities. Family being at the heart of the ethics and values of the company, Maa Homes strives to provide a best-in-class lifestyle at the most competent price possible.
</p>

                <p  className="AboutExpoPara">The company has built its reputation of providing a great experience across the nation. Maa Homes assure to deliver on the standards it has set over the years and that makes the communities at Maa Homes strong and well-connected. Maa agents have proven themselves in all types of properties and investments which helps them give you your dream home with complete and clear communication at each touchpoint of the process.
</p>
            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default MaaHomesReadMore;