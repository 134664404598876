import React from 'react';
import ReactPlayer from 'react-player';
import './assets/styles/_index.scss';
import { useRef } from 'react';
import { useState } from 'react';

const VideoPlayer = ({ videoUrl, thumbnailUrl,videoTitle }) => {
    const [playing, setPlaying] = useState(true);
    const playerRef = useRef(null);
   
  
    const handlePlayClick = () => {
      setPlaying(true);
    };
  
    const handleVideoEnded = () => {
      setPlaying(false);
    };
    return (
      <div className="video-card">
        <div className="video-card__thumbnail" >
          <ReactPlayer
          ref={playerRef}
            url={videoUrl}
            light={thumbnailUrl}
            controls={true}
            width="100%"
            height="100%"
            className="videoplayer"
            playing={playing}
          onEnded={handleVideoEnded}
          />
          {!playing && (
          <div className="video-card__play-button" onClick={handlePlayClick}>
            
          </div>
        )}
        </div>
       
      </div>
    );
  };
  
export default VideoPlayer;